<template>
  <div>
    <ModalConfirm
      :showModal="open"
      imageProps="delete-area-component.png"
      titleProps="Jam Survey Tidak Valid"
      descProps="Anda dapat mengganti jam survey dengan list dibawah ini"
    >
      <template v-slot:additionalDesc>
        <div class="additional-avail-survey bg-lightgray border-radius-100 p-3 mt-3">
          <div class="flex-center averta-bold">
            <p v-for="(item, index) in availableSurveys" :key="index">
              {{ item }} <span class="mr-1 pr-1">{{index + 1 !== availableSurveys.length ? '|' : ''}}</span>
            </p>
          </div>
        </div>
      </template>
      <template v-slot:button>
        <div class="c-w-100">
          <ButtonApp width="100%" @click="closeModal">
            <div>
              <p class="averta-bold py-2">Mengerti</p>
            </div>
          </ButtonApp>
        </div>
      </template>
    </ModalConfirm>
  </div>
</template>

<script>
export default {
  name: 'ModalErrorMove',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    availableSurveys: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  components: {
    ModalConfirm: () => import('../../../am/vendor/components/ModalConfirm.vue'),
    ButtonApp: () => import('@/components/button/ButtonApp.vue')
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>
